.slick-dots {
  display: flex;
  justify-content: center;
  /* background-color: rgba(253, 253, 253, 0.217); */
  padding: 35px 0;
  list-style-type: none;

  & button {
    display: block;
    background-color: rgba(125, 95, 5, 0.504) !important;
    border: none;
    border-radius: 100%;
    text-indent: -9999px;
  }
  & li.slick-active button {
    background-color: rgba(7, 7, 8, 0.737) !important;
  }
}
